
<template>
  <div class="main-content">
    <div class="row mb-3">
      <div class="col-md-3">
        <label>Filter by year: </label>
        <select class="form-control" v-model="filterYear">
          <option :value="year" v-for="year in years">{{ year }}</option>
        </select>
      </div>
      <div class="col-md-3">
        <label>Filter by month: </label>
        <select class="form-control" v-model="filterMonth">
            <option :value="month" v-for="month in months">{{ month }}</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">

        <b-table striped hover :items="sales" :fields="report_columns">


        </b-table>
      </div>
    </div>


  </div>
</template>
<script>
export default {
  data() {
    return {
      filterYear:"",

      months:[
          "January","February","March","April","May","June","July","August","September","October","November","December"
      ],
      filterMonth:"",
      years:[2021,2022,2023],
      sales:[],
      report_columns:["user","location", "weekOne","weekTwo","weekThree","weekFour","totalSales","percentageAchieved"]
    };
  },
  mounted (){
    this.updateCurrentMonthAndYear();
  },
  methods:{
    updateCurrentMonthAndYear(){
      this.filterMonth = this.months[new Date().getMonth()];
      this.filterYear = new Date().getFullYear();
      console.log(this.filterYear);
    }
  }
};
</script>
